.inputs {
  margin: 0.05rem 0.5rem;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  min-width: 18rem;
  padding: 0.5rem 0.5rem;
  /* border: "2px solid grey"; */
  border-radius: 0.5rem;
}
.logininputs {
  margin: 0.25rem;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  min-width: 17rem;
  padding: 0.5rem 0.5rem;
  /* border: "2px solid grey"; */
  border-radius: 0.5rem;
}

.buttons {
  margin:0.25rem;
  /* border: 2px solid slategray;
  padding: 5px 10px;
  height: 3.75em; */

}

.section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0.25rem;
  /* align-items: center; */
  /* min-height: 100vh;  */
  padding: 0rem 0.5rem;
  
}
.form {
  border: 2px solid gray;
  width: auto;
  height: auto;
  align-items: stretch;
  padding: 0.5rem 0.75rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
}

.formh4 {
  border: 1px solid gray;
  width: auto;
  margin: 1rem;
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  text-align: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.4);
  height: 3rem;
}

.customerProfileDiv {
  margin : 0.5rem;
  padding: 0.5rem;
  background-color:violet;
  border:2px solid gray;
  border-radius:0.75rem;
}
.labelmargin {
  /* margin: 0.005rem 0.5rem; */
  margin-top: 0.15em;
    margin-left: 0.5em;
    margin-right: 0.5em;
}