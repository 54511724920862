.appBar {
  border-radius: 15;
  margin: '2rem';
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
}
.heading {
  color: 'rgba(0,183,255, 1)';
}
.image {
  margin-left: '15px';
}